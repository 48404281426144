/* Home Page Css Start */
.homepage {
  position: relative;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  /* height: 100vh; */
  /* min-height: 100vh; */
  height: calc(var(--vh, 1vh) * 100);
}
.homepage video {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
}
.homepage .home_btm_txt {
  position: absolute;
  bottom: 40px;
  left: 0;
  text-align: center;
  width: 100%;
}
.homepage .home_btm_txt h2 {
  color: #ffffff;
  font-size: 30px;
  padding: 0 0px 0 5px;
  transform-origin: bottom center;
  opacity: 0.8;
}
/* Home Page Css Close */
