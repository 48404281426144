/* Animation */
.universal-ani-enter {
  opacity: 0;
}
.universal-ani-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}
.universal-ani-exit {
  opacity: 1;
}
.universal-ani-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}

.universal_page {
  padding: 70px 70px 0 70px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* overflow: scroll; */
  /* background-color: ; */
  color: white;
  font-size: 8px;
  font-family: "IBM Plex Mono", monospace;
  margin: auto;
}

.universal_content {
  /* display: flex;
  flex-direction: column;
  width: 100%; */
  /* margin: auto; */
}

.universal_page a {
  cursor: pointer;
}

.universal_page .disabled {
  pointer-events: none;
  cursor: default;
  color: grey !important;
}

.universal_content img {
  width: 375px;
  transform: scale(1, 0.85);
  margin-bottom: -17px;
}
.universal_content p {
  font-size: 18px;
  margin: auto;
  width: 375px;
  font-family: adobe-garamond-pro, serif;
  font-style: normal;
  transform: scale(0.85, 1);
  transform-origin: bottom;
  text-align: left;
  line-height: 22px;
}

.universal-controls {
  display: flex;
  margin: auto;
  position: relative;
  flex-direction: row;
  justify-content: center;
  font-size: 10px;
}

.universal-controls a {
  color: white;
}
.white-writing a {
  color: white;
}

@media only screen and (max-width: 675px) {
  .universal_page {
    padding: 75px 12px 0 12px;
  }
  .universal_content img {
    width: 100%;
  }
  .universal_content p {
    width: 100%;
  }
}
