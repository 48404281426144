.instagram-page {
  padding: 120px 50px 0px 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: "IBM Plex Mono", monospace;
}

.image {
  position: relative;
  display: inline-block;
  /* width: 29%; */
  flex: 0 0 33%;
  height: auto;
  margin-bottom: 4px;
}
/* .image:nth-child(3n) {
  padding-left: 12px;
}
.image:nth-child(3n-1) {
  padding: 0 12px;
} */

.overlay {
  display: none;
}
.image:hover .overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media only screen and (max-width: 1100px) {
}
@media only screen and (max-width: 675px) {
  .instagram-page {
    padding: 75px 12px 12px 12px;
  }
  /* .image {
    width: 40%;
    margin: 10px;
  } */
}
