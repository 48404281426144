#popup {
  z-index: 99;
  font-family: "IBM Plex Mono", monospace;
}
#popup {
  width: 40%;
  margin: auto;
}

.ReactModal__Overlay {
  z-index: 99;
}

.modal-close {
  position: absolute;
  right: 20px;
  border: 0;
  background-color: white;
  font-size: 20px;
}
/* .shop_page button {
  background-color: white;
  border: 0;
  text-decoration: none !important;
} */
@media only screen and (max-width: 750px) {
  #popup {
    width: auto;
    margin: auto;
  }
}
