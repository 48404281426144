/* CART
 * ============================== */
.cart-page {
  /* height: 100vh; */
  /* height: calc(var(--vh, 1vh) * 100); */
  width: 100%;
  max-width: 916px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 120px 20px 75px 20px;
  font-family: "IBM Plex Mono", monospace;
  margin: auto;
}

.empty-cart {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 75px 0 0 0;
  text-align: center;
  font-size: 12px;
  font-family: "IBM Plex Mono", monospace;
}
.empty-cart a {
  text-decoration: underline !important;
}

.pricing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 36px 0;
  font-size: 16px;
  color: black;
  width: 400px;
}

.checkout-button {
  right: 0;
  color: white;
  background-color: black;
  width: 175px;
  height: 40px;
  font-size: 20px;
  font-family: adobe-garamond-pro, serif;
  font-style: italic;
}
.cart-finale {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.cart-sub-finale {
  display: flex;
  flex-direction: column;
}

/* LINE ITEMS
 * ============================== */

.line-item {
  border-bottom: 1px solid black;
  width: 100%;
  padding: 36px 0 30px 0;
}
.line-item-img img {
  height: 125px;
}
.line-item-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.line-item-edit {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.line-item-edit button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.line-item-edit button:hover {
  color: rgb(54, 58, 82);
}

.cart-table-headers {
  display: flex;
  border-bottom: 1px solid black;
  padding-bottom: 18px;
}

.cart-img {
  width: 15%;
}
.cart-product-name {
  width: 60%;
  text-align: left;
  vertical-align: top;
  display: flex;
  flex-direction: column;
}

.cart-quantity {
  width: 15%;
  text-align: center;
  vertical-align: top;
}
.cart-quantity-mobile {
  display: none;
}
.cart-price {
  width: 15%;
  text-align: center;
  vertical-align: top;
}

@media only screen and (max-width: 675px) {
  .cart-page {
    height: 100%;
  }
  .cart-table-headers div {
    display: none;
  }
  .checkout-button {
    width: 100%;
  }
  .cart-sub-finale {
    width: 100%;
  }
  .cart-img {
    width: 25%;
  }
  .cart-product-name {
    width: 50%;
  }
  .cart-quantity {
    display: none;
  }
  .cart-price {
    text-align: right;
    width: 20%;
  }
  .cart-quantity-mobile {
    display: block;
  }
  .line-item-img img {
    height: 100px;
  }
  .pricing {
    width: 100%;
  }
}
