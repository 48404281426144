/* .carousel-inner .item {
  backface-visibility: visible;
} */
.looks-page {
  padding: 70px 70px 0 70px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.looks-content {
  display: flex;
  flex-direction: column;
  width: 700px;
  margin: auto;
}

.looks-carousel {
  margin-bottom: 16px;
  min-width: 0;
  min-height: 0;
}

.looks-content button {
  background: transparent;
}
.arrow-img {
  height: auto !important;
  width: auto !important;
}

.looks-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.looks-title {
  text-align: left;
  font-size: 20px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
}

.looks-description {
  font-size: 10px;
  font-family: "IBM Plex Mono", monospace;
}

.looks-archive {
  font-size: 14px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom right;
  font-style: italic;
  text-align: right;
}

.looks-archive-list {
  font-size: 8px;
  font-family: "IBM Plex Mono", monospace;
  display: none;
}
.looks-archive:hover .looks-archive-list {
  display: block;
}

.carousel-indicators {
  display: none !important;
}

.ril__image {
  max-height: fit-content;
  /* height: 100%; */
  /* transform: none !important; */
}
.ril__inner {
  background-color: rgba(255, 255, 255, 1);
}
.ril__toolbar {
  background: transparent;
}
.ril_navButtons {
  background-color: transparent !important;
}

@media only screen and (max-width: 430px) {
  .looks-page {
    padding: 50px 30px 0 30px;
  }
  .looks-content {
    width: 90% !important;
  }
}
@media only screen and (max-width: 440px) and (max-height: 600px) {
  .looks-content {
    width: 50% !important;
  }
}

/* @media only screen and (max-height: 650px) {
  .looks-content {
    width: 100%;
  }
} */
/* @media only screen and (max-height: 750px) {
  .looks-content {
    width: 200px;
  }
} */
@media only screen and (max-height: 820px) {
  .looks-content {
    width: 290px;
  }
}
@media only screen and (max-height: 1200px) and (min-height: 820px) {
  .looks-content {
    width: 400px;
  }
}
@media only screen and (max-height: 1400px) and (min-height: 1200px) {
  .looks-content {
    width: 600px;
  }
}
