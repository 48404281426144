.article-page {
  padding: 120px 0 0 0;
  /* height: 100vh; */
  width: 100%;
  display: flex;
  /* overflow: scroll; */
  text-align: center;
  font-size: 12px;
  font-family: "IBM Plex Mono", monospace;
}

.article-page p {
  text-align: center !important;
  width: 450px;
  margin: auto;
}

.article-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.article-content button {
  /* width: 650px; */
  margin: auto;
}

.article-content span {
  display: block;
  width: 450px;
  margin: auto;
}

.article-content-img {
  margin: 6px auto;
  width: 650px;
}

.article-title {
  font-size: 26px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom center;
  font-style: italic;
  margin: 0px 0 8px 0;
}

@media only screen and (max-width: 800px) {
  .article-page {
    padding: 75px 20px 0 20px;
  }
  .article-content img {
    /* width: 300px; */
    width: 100%;
    margin: 7px auto;
  }
  .article-content span {
    width: 100%;
  }
  .article-content p {
    width: 100%;
  }
  .article-content .img-span {
    width: 100%;
  }
}
