.product-page {
  padding-top: 120px;
  padding-left: 25%;
  /* padding-right: 75px; */
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
}

.product-images {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-bottom: 50px;
  height: 100%;
}

.product-images div {
  margin-top: 5px;
}
.product-image {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
.product-images-carousel {
  display: none;
  /* margin: 0 auto; */
}

.product-info {
  display: flex;
  flex-direction: column;
  top: 16%;
  width: 350px;
  padding-right: 20px;
  margin: 20px;
  left: 63%;
  position: fixed;
}

.product-title {
  height: 20px;
  font-size: 20px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
  margin-bottom: 10px;
  order: 1;
}

.product-price {
  font-size: 14px;
  font-family: "IBM Plex Mono", monospace;
  order: 2;
}

.product-description {
  font-size: 10px;
  font-family: "IBM Plex Mono", monospace;
  margin: 30px 0;
  order: 3;
}

.size-buttons {
  order: 4;
}
.size-button:first-child {
  margin-left: 0px;
}
.size-button:last-child {
  margin-right: 0px;
}

.size-button {
  width: 30px;
  height: 30px;
  color: black;
  padding: 0;
  margin: 0 2px;
  background-color: white;
  cursor: pointer;
  font-size: 8px;
  font-family: "IBM Plex Mono", monospace;
  border: 1px solid;
}

.not-available {
  background-color: white !important;
  cursor: not-allowed !important;
  color: #c1bdb8 !important;
  border: 1px solid #c1bdb8;
  text-decoration: line-through !important;
}
.add-not-available {
  background-color: white !important;
  cursor: not-allowed !important;
  color: black !important;
}

.add-to-cart {
  width: 166px;
  height: 30px;
  font-size: 16px;
  font-family: adobe-garamond-pro, serif;
  font-style: italic;
  color: white;
  padding: 0;
  margin: 10px 0px 20px 0px;
  background-color: black;
  cursor: not-allowed;
  order: 5;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  background-color: #c1bdb8;
  cursor: pointer;
}

.size-button:hover {
  background-color: #c1bdb8;
}

.selected {
  color: white;
  background-color: black;
}
button:focus {
  outline: none;
}

.product-sub-details {
  display: flex;
  flex-direction: column;
  order: 6;
}
.product-details {
  font-size: 9px;
  font-family: "IBM Plex Mono", monospace;
  background-color: transparent;
  border: 0;
  width: 50px;
}
@media only screen and (max-width: 933px) {
  .product-page {
    padding-left: 10%;
    /* padding: 75px 0px 0 0px; */
  }
  .product-info {
    left: 55%;
  }
}

@media only screen and (max-width: 800px) {
  .product-title {
    margin: 0;
  }
  .product-price {
    margin: 5px 0 10px 0;
  }
  .product-page {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 75px 0px 0 0px;
  }
  .product-info {
    position: relative;
    /* width: 100%; */
    top: 0;
    left: 0;
    padding: 10px 20px;
    margin: auto;
  }
  .product-images {
    display: none !important;
  }
  .product-description {
    width: 316px;
    margin: 15px 0;
    order: 6;
  }
  .size-buttons {
    order: 3;
  }
  .add-to-cart {
    width: 100%;
    height: 30px;
    font-size: 16px;
    order: 4;
  }
  .product-images-carousel {
    display: block;
    width: 325px;
    margin-bottom: 36px;
    margin: auto;
  }

  .product-images-carousel img {
    /* height: 50%; */
    height: auto;
    width: 100%;
    margin: auto;
  }
  .product-images-carousel button {
    background: transparent;
  }
  .arrow-img {
    height: auto !important;
    width: auto !important;
  }
}

@media only screen and (max-width: 450px) {
  .product-info {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    padding: 10px 20px;
    margin: 0;
  }
  .product-images-carousel {
    display: block;
    width: 80%;
    margin-bottom: 36px;
    margin: auto;
  }
  .product-images-carousel img {
    /* height: 50%; */
    height: auto;
    width: 100%;
    margin: auto;
  }
}
