.about-page {
  padding: 22px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  /* overflow: scroll; */
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: "IBM Plex Mono", monospace;
}

.about-content {
  max-width: 400px;
  margin: auto;
}

@media only screen and (max-width: 675px) {
  .about-page {
    padding-top: 20px;
    font-size: 10px;
  }
  .about-content {
    max-width: 300px;
    margin: auto;
  }
}
