.ril-zoom-in {
  background: url("/img/zoom_in.svg") no-repeat center;
  height: 25px;
}
.ril-zoom-out {
  background: url("/img/zoom_out.svg") no-repeat center;
  height: 25px;
}
.ril-close {
  background: url("/img/close.svg") no-repeat center;
  height: 25px;
}
.ril-next-button {
  background: url("/img/next_img.svg") no-repeat center;
  padding: 0 40px 0 0;
}
.ril-prev-button {
  background: url("/img/prev_img.svg") no-repeat center;
  padding: 0 0px 0 40px;
}
