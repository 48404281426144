/* Main Menu Css Start */
.main_menu {
  position: fixed;
  left: 50px;
  text-align: left;
  margin: 0;
  top: 50px;
  z-index: 2;
  list-style: none;
  padding: 0;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
}
.main_menu li {
  list-style: none;
}
.main_menu li + li {
}
.main_menu li:nth-child(4) {
  margin-top: 20px;
}
.main_menu li a {
  color: #ff3211;
  font-size: 20px;
  text-decoration: none !important;
  line-height: 22.19px;
}
/* .home_menu {
} */
.home_menu li a {
  color: #ffffff;
}
.home_logo {
  position: fixed;
  top: 40px;
  z-index: 1;
  left: 0;
  text-align: center;
  width: 100%;
  margin: auto;
}
.home_logo a {
  display: inline-block;
}

.footer-text {
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  margin-top: 100px;
}

.nav-collections {
  display: none;
  padding-inline-start: 25px;
}

.nav-dropdown:hover .nav-collections {
  display: block;
}

.nav-active {
  font-weight: bold;
  display: block !important;
}
.nav-option a:hover {
  color: rgb(54, 58, 82);
}

@media only screen and (max-width: 675px) {
  .nav-option {
    display: none;
  }
  /* nav:hover .nav-option {
    display: block;
  } */
  .main_menu {
    left: 12px;
  }
}
/* Main Menu Css Close */

/* Shop Modals */
.shop-modals {
  position: fixed;
  display: flex;
  flex-direction: row;
  right: 50px;
  top: 54px;
  justify-content: flex-end;
  font-family: "IBM Plex Mono", monospace;
  font-size: 10px;
  z-index: 99;
  text-align: right;
}
.shop-sub-modals {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 !important;
  font-size: 9px;
  width: 200px;
}
.shop-sub-modals button {
  font-family: "IBM Plex Mono", monospace;
  border: 0px;
  background-color: transparent;
  font-size: 9px;
  cursor: pointer;
  color: grey;
}
.shop-sub-modals button:hover {
  color: rgb(54, 58, 82);
}
@media only screen and (max-width: 800px) {
  .shop-sub-modals {
    width: 60%;
  }
}
@media only screen and (max-width: 933px) {
  .shop-sub-modals {
    display: none; /* this disables */
    width: 100%;
    /* display: block; */
    order: 2;
  }
  .nav-active {
    font-weight: normal;
  }
}
@media only screen and (max-width: 675px) {
  .home_logo {
    top: 20px;
  }
  .main_menu {
    top: 25px;
  }
  .shop-modals {
    top: 25px;
    width: 100px;
    right: 12px;
    flex-direction: column;
  }
  .shop-cart-modal {
    order: 1;
  }
}

html,
body {
  margin: 0;
}

/* BURGER */
#App {
  font-family: sans-serif;

  /* Give app full page to work with */
  height: 100vh;
}

#page-wrap {
  /* text-align: center; */

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  /* margin-bottom: 10px; */
  transition: color 0.2s;

  color: #ff3211;
  font-size: 24px;
  text-decoration: none !important;
  line-height: 30px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
  outline: 0;
}
.bm-item:nth-child(4) {
  margin-top: 20px;
}
/* Change color on hover */
/* .bm-item:hover {
  color: white;
} */

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu-wrap {
  width: 220px !important;
}
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  opacity: 1;
}
.bm-active {
  font-weight: bold;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.shop-sub-modals-side {
  position: absolute;
  bottom: 25px;
  display: flex !important;
  text-align: left;
  line-height: 20px;
  flex-direction: column;
  font-size: 13px;
  font-family: "IBM Plex Mono", monospace;
  transform: none;
}
.shop-sub-modals-side button {
  background-color: transparent;
  display: flex !important;
  text-align: left;
  flex-direction: column;
  padding: 0;
  cursor: pointer;
  font-family: "IBM Plex Mono", monospace;
}
.shop-sub-modals-side button:hover {
  color: rgb(54, 58, 82);
}
