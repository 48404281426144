.values-page {
  display: flex;
  flex-direction: row;
  padding: 150px;
  margin: auto 0;
  height: 100vh;
  width: 100%;
  justify-content: center;
}
.values-content {
  /* max-width: 350px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
  margin: auto;
}
.values-content img {
  max-width: 316px;
  max-height: 451px;
  height: auto;
  width: auto;
}

@media only screen and (max-width: 940px) {
  .page .credits-stick {
    position: relative;
  }
  .values-page {
    padding-bottom: 20px;
    height: auto;
  }
}
@media only screen and (max-width: 675px) {
  .values-page {
    padding: 75px 12px 20px 12px;
    height: auto;
  }
}
