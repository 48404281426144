.contact-page {
  padding: 12px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* overflow: scroll; */
  font-size: 10px;
  font-family: "IBM Plex Mono", monospace;
}

.contact-form {
  width: 500px;
  max-height: 600px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  margin: auto;
}

.names {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: space-between;
}
.s_name {
  display: flex;
  flex-direction: column;
  width: 242px;
}
.name {
  width: 100%;
}

.inputdiv {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.email_div {
  display: flex;
  flex-direction: column;
  width: 308px;
}

.order_div {
  display: flex;
  flex-direction: column;
  width: 176px;
}

.contact-page label {
  margin: 36px 0 21px 0;
}

.subject-selector {
  height: 30px;
  padding: 5px;
  border-radius: 0;
}
.message {
  height: 100px;
  border-radius: 0;
  resize: none;
  margin: 0 0 18px 0;
}
.submit-button {
  height: 35px;
  width: 200px;
  color: white;
  background-color: black;
  font-family: adobe-garamond-pro, serif;
  font-style: italic;
  font-size: 20px;
  border: 0;
  right: 0;
}

input {
  border: 0;
  border-radius: 0;
  border-bottom: solid 1px gray;
}
input:focus {
  outline: 0;
}

@media only screen and (max-width: 675px) {
  .contact-page {
    padding: 75px 30px;
    height: auto;
  }
  .contact-form {
    width: 100%;
    max-height: 500px;
  }
  .s_name {
    width: 100%;
  }
  .email_div {
    width: 100%;
  }
  .order_div {
    width: 100%;
  }
  .names {
    flex-direction: column;
    height: 100%;
  }
  .submit-button {
    width: 100%;
  }
  .credits-stick {
    position: relative;
  }
}

.react-dropdown-select {
  border: 1px solid black !important;
  border-radius: 0px;
}
