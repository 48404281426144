@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400&display=swap);
@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css);
.ril-zoom-in {
  background: url("/img/zoom_in.svg") no-repeat center;
  height: 25px;
}
.ril-zoom-out {
  background: url("/img/zoom_out.svg") no-repeat center;
  height: 25px;
}
.ril-close {
  background: url("/img/close.svg") no-repeat center;
  height: 25px;
}
.ril-next-button {
  background: url("/img/next_img.svg") no-repeat center;
  padding: 0 40px 0 0;
}
.ril-prev-button {
  background: url("/img/prev_img.svg") no-repeat center;
  padding: 0 0px 0 40px;
}

#popup {
  z-index: 99;
  font-family: "IBM Plex Mono", monospace;
}
#popup {
  width: 40%;
  margin: auto;
}

.ReactModal__Overlay {
  z-index: 99;
}

.modal-close {
  position: absolute;
  right: 20px;
  border: 0;
  background-color: white;
  font-size: 20px;
}
/* .shop_page button {
  background-color: white;
  border: 0;
  text-decoration: none !important;
} */
@media only screen and (max-width: 750px) {
  #popup {
    width: auto;
    margin: auto;
  }
}

.product-page {
  padding-top: 120px;
  padding-left: 25%;
  /* padding-right: 75px; */
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
}

.product-images {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-bottom: 50px;
  height: 100%;
}

.product-images div {
  margin-top: 5px;
}
.product-image {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
.product-images-carousel {
  display: none;
  /* margin: 0 auto; */
}

.product-info {
  display: flex;
  flex-direction: column;
  top: 16%;
  width: 350px;
  padding-right: 20px;
  margin: 20px;
  left: 63%;
  position: fixed;
}

.product-title {
  height: 20px;
  font-size: 20px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
  margin-bottom: 10px;
  order: 1;
}

.product-price {
  font-size: 14px;
  font-family: "IBM Plex Mono", monospace;
  order: 2;
}

.product-description {
  font-size: 10px;
  font-family: "IBM Plex Mono", monospace;
  margin: 30px 0;
  order: 3;
}

.size-buttons {
  order: 4;
}
.size-button:first-child {
  margin-left: 0px;
}
.size-button:last-child {
  margin-right: 0px;
}

.size-button {
  width: 30px;
  height: 30px;
  color: black;
  padding: 0;
  margin: 0 2px;
  background-color: white;
  cursor: pointer;
  font-size: 8px;
  font-family: "IBM Plex Mono", monospace;
  border: 1px solid;
}

.not-available {
  background-color: white !important;
  cursor: not-allowed !important;
  color: #c1bdb8 !important;
  border: 1px solid #c1bdb8;
  text-decoration: line-through !important;
}
.add-not-available {
  background-color: white !important;
  cursor: not-allowed !important;
  color: black !important;
}

.add-to-cart {
  width: 166px;
  height: 30px;
  font-size: 16px;
  font-family: adobe-garamond-pro, serif;
  font-style: italic;
  color: white;
  padding: 0;
  margin: 10px 0px 20px 0px;
  background-color: black;
  cursor: not-allowed;
  order: 5;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  background-color: #c1bdb8;
  cursor: pointer;
}

.size-button:hover {
  background-color: #c1bdb8;
}

.selected {
  color: white;
  background-color: black;
}
button:focus {
  outline: none;
}

.product-sub-details {
  display: flex;
  flex-direction: column;
  order: 6;
}
.product-details {
  font-size: 9px;
  font-family: "IBM Plex Mono", monospace;
  background-color: transparent;
  border: 0;
  width: 50px;
}
@media only screen and (max-width: 933px) {
  .product-page {
    padding-left: 10%;
    /* padding: 75px 0px 0 0px; */
  }
  .product-info {
    left: 55%;
  }
}

@media only screen and (max-width: 800px) {
  .product-title {
    margin: 0;
  }
  .product-price {
    margin: 5px 0 10px 0;
  }
  .product-page {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 75px 0px 0 0px;
  }
  .product-info {
    position: relative;
    /* width: 100%; */
    top: 0;
    left: 0;
    padding: 10px 20px;
    margin: auto;
  }
  .product-images {
    display: none !important;
  }
  .product-description {
    width: 316px;
    margin: 15px 0;
    order: 6;
  }
  .size-buttons {
    order: 3;
  }
  .add-to-cart {
    width: 100%;
    height: 30px;
    font-size: 16px;
    order: 4;
  }
  .product-images-carousel {
    display: block;
    width: 325px;
    margin-bottom: 36px;
    margin: auto;
  }

  .product-images-carousel img {
    /* height: 50%; */
    height: auto;
    width: 100%;
    margin: auto;
  }
  .product-images-carousel button {
    background: transparent;
  }
  .arrow-img {
    height: auto !important;
    width: auto !important;
  }
}

@media only screen and (max-width: 450px) {
  .product-info {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    padding: 10px 20px;
    margin: 0;
  }
  .product-images-carousel {
    display: block;
    width: 80%;
    margin-bottom: 36px;
    margin: auto;
  }
  .product-images-carousel img {
    /* height: 50%; */
    height: auto;
    width: 100%;
    margin: auto;
  }
}

/* CART
 * ============================== */
.cart-page {
  /* height: 100vh; */
  /* height: calc(var(--vh, 1vh) * 100); */
  width: 100%;
  max-width: 916px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 120px 20px 75px 20px;
  font-family: "IBM Plex Mono", monospace;
  margin: auto;
}

.empty-cart {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 75px 0 0 0;
  text-align: center;
  font-size: 12px;
  font-family: "IBM Plex Mono", monospace;
}
.empty-cart a {
  text-decoration: underline !important;
}

.pricing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 36px 0;
  font-size: 16px;
  color: black;
  width: 400px;
}

.checkout-button {
  right: 0;
  color: white;
  background-color: black;
  width: 175px;
  height: 40px;
  font-size: 20px;
  font-family: adobe-garamond-pro, serif;
  font-style: italic;
}
.cart-finale {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.cart-sub-finale {
  display: flex;
  flex-direction: column;
}

/* LINE ITEMS
 * ============================== */

.line-item {
  border-bottom: 1px solid black;
  width: 100%;
  padding: 36px 0 30px 0;
}
.line-item-img img {
  height: 125px;
}
.line-item-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.line-item-edit {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.line-item-edit button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.line-item-edit button:hover {
  color: rgb(54, 58, 82);
}

.cart-table-headers {
  display: flex;
  border-bottom: 1px solid black;
  padding-bottom: 18px;
}

.cart-img {
  width: 15%;
}
.cart-product-name {
  width: 60%;
  text-align: left;
  vertical-align: top;
  display: flex;
  flex-direction: column;
}

.cart-quantity {
  width: 15%;
  text-align: center;
  vertical-align: top;
}
.cart-quantity-mobile {
  display: none;
}
.cart-price {
  width: 15%;
  text-align: center;
  vertical-align: top;
}

@media only screen and (max-width: 675px) {
  .cart-page {
    height: 100%;
  }
  .cart-table-headers div {
    display: none;
  }
  .checkout-button {
    width: 100%;
  }
  .cart-sub-finale {
    width: 100%;
  }
  .cart-img {
    width: 25%;
  }
  .cart-product-name {
    width: 50%;
  }
  .cart-quantity {
    display: none;
  }
  .cart-price {
    text-align: right;
    width: 20%;
  }
  .cart-quantity-mobile {
    display: block;
  }
  .line-item-img img {
    height: 100px;
  }
  .pricing {
    width: 100%;
  }
}

/* Main Menu Css Start */
.main_menu {
  position: fixed;
  left: 50px;
  text-align: left;
  margin: 0;
  top: 50px;
  z-index: 2;
  list-style: none;
  padding: 0;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
}
.main_menu li {
  list-style: none;
}
.main_menu li + li {
}
.main_menu li:nth-child(4) {
  margin-top: 20px;
}
.main_menu li a {
  color: #ff3211;
  font-size: 20px;
  text-decoration: none !important;
  line-height: 22.19px;
}
/* .home_menu {
} */
.home_menu li a {
  color: #ffffff;
}
.home_logo {
  position: fixed;
  top: 40px;
  z-index: 1;
  left: 0;
  text-align: center;
  width: 100%;
  margin: auto;
}
.home_logo a {
  display: inline-block;
}

.footer-text {
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  margin-top: 100px;
}

.nav-collections {
  display: none;
  -webkit-padding-start: 25px;
          padding-inline-start: 25px;
}

.nav-dropdown:hover .nav-collections {
  display: block;
}

.nav-active {
  font-weight: bold;
  display: block !important;
}
.nav-option a:hover {
  color: rgb(54, 58, 82);
}

@media only screen and (max-width: 675px) {
  .nav-option {
    display: none;
  }
  /* nav:hover .nav-option {
    display: block;
  } */
  .main_menu {
    left: 12px;
  }
}
/* Main Menu Css Close */

/* Shop Modals */
.shop-modals {
  position: fixed;
  display: flex;
  flex-direction: row;
  right: 50px;
  top: 54px;
  justify-content: flex-end;
  font-family: "IBM Plex Mono", monospace;
  font-size: 10px;
  z-index: 99;
  text-align: right;
}
.shop-sub-modals {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 !important;
  font-size: 9px;
  width: 200px;
}
.shop-sub-modals button {
  font-family: "IBM Plex Mono", monospace;
  border: 0px;
  background-color: transparent;
  font-size: 9px;
  cursor: pointer;
  color: grey;
}
.shop-sub-modals button:hover {
  color: rgb(54, 58, 82);
}
@media only screen and (max-width: 800px) {
  .shop-sub-modals {
    width: 60%;
  }
}
@media only screen and (max-width: 933px) {
  .shop-sub-modals {
    display: none; /* this disables */
    width: 100%;
    /* display: block; */
    order: 2;
  }
  .nav-active {
    font-weight: normal;
  }
}
@media only screen and (max-width: 675px) {
  .home_logo {
    top: 20px;
  }
  .main_menu {
    top: 25px;
  }
  .shop-modals {
    top: 25px;
    width: 100px;
    right: 12px;
    flex-direction: column;
  }
  .shop-cart-modal {
    order: 1;
  }
}

html,
body {
  margin: 0;
}

/* BURGER */
#App {
  font-family: sans-serif;

  /* Give app full page to work with */
  height: 100vh;
}

#page-wrap {
  /* text-align: center; */

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  /* margin-bottom: 10px; */
  transition: color 0.2s;

  color: #ff3211;
  font-size: 24px;
  text-decoration: none !important;
  line-height: 30px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
  outline: 0;
}
.bm-item:nth-child(4) {
  margin-top: 20px;
}
/* Change color on hover */
/* .bm-item:hover {
  color: white;
} */

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu-wrap {
  width: 220px !important;
}
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  opacity: 1;
}
.bm-active {
  font-weight: bold;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.shop-sub-modals-side {
  position: absolute;
  bottom: 25px;
  display: flex !important;
  text-align: left;
  line-height: 20px;
  flex-direction: column;
  font-size: 13px;
  font-family: "IBM Plex Mono", monospace;
  transform: none;
}
.shop-sub-modals-side button {
  background-color: transparent;
  display: flex !important;
  text-align: left;
  flex-direction: column;
  padding: 0;
  cursor: pointer;
  font-family: "IBM Plex Mono", monospace;
}
.shop-sub-modals-side button:hover {
  color: rgb(54, 58, 82);
}

/* Home Page Css Start */
.homepage {
  position: relative;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  /* height: 100vh; */
  /* min-height: 100vh; */
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
}
.homepage video {
  width: 100%;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
}
.homepage .home_btm_txt {
  position: absolute;
  bottom: 40px;
  left: 0;
  text-align: center;
  width: 100%;
}
.homepage .home_btm_txt h2 {
  color: #ffffff;
  font-size: 30px;
  padding: 0 0px 0 5px;
  transform-origin: bottom center;
  opacity: 0.8;
}
/* Home Page Css Close */

.news_page {
  padding: 120px 50px 0px 50px;
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.news-article {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-article img {
  width: 100%;
  position: relative;
}

.news-article .news-article-title {
  margin: 10px 0 2px 0;
  height: 26px;
  font-size: 20px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
  overflow: hidden;
}

.news-article .news-article-date {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  /* line-height: 18px; */
  font-size: 10px;
  height: 10px;
}
.grid_page_odd::after {
  content: "";
  /* flex: auto; */
  width: 32%;
}

@media only screen and (max-width: 675px) {
  .news_page {
    padding: 75px 12px 0px 12px;
  }
  .news-article .news-article-title {
    margin: 5px 0 0 0;
    font-size: 16px;
  }

  .news-article .news-article-date {
    /* font-size: 8px; */
  }
  .news_page .product-container:nth-of-type(odd) {
    padding-right: 6px;
  }
  .news_page .product-container:nth-of-type(even) {
    padding-left: 6px;
  }
}

/* .carousel-inner .item {
  backface-visibility: visible;
} */
.looks-page {
  padding: 70px 70px 0 70px;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.looks-content {
  display: flex;
  flex-direction: column;
  width: 700px;
  margin: auto;
}

.looks-carousel {
  margin-bottom: 16px;
  min-width: 0;
  min-height: 0;
}

.looks-content button {
  background: transparent;
}
.arrow-img {
  height: auto !important;
  width: auto !important;
}

.looks-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.looks-title {
  text-align: left;
  font-size: 20px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
}

.looks-description {
  font-size: 10px;
  font-family: "IBM Plex Mono", monospace;
}

.looks-archive {
  font-size: 14px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom right;
  font-style: italic;
  text-align: right;
}

.looks-archive-list {
  font-size: 8px;
  font-family: "IBM Plex Mono", monospace;
  display: none;
}
.looks-archive:hover .looks-archive-list {
  display: block;
}

.carousel-indicators {
  display: none !important;
}

.ril__image {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  /* height: 100%; */
  /* transform: none !important; */
}
.ril__inner {
  background-color: rgba(255, 255, 255, 1);
}
.ril__toolbar {
  background: transparent;
}
.ril_navButtons {
  background-color: transparent !important;
}

@media only screen and (max-width: 430px) {
  .looks-page {
    padding: 50px 30px 0 30px;
  }
  .looks-content {
    width: 90% !important;
  }
}
@media only screen and (max-width: 440px) and (max-height: 600px) {
  .looks-content {
    width: 50% !important;
  }
}

/* @media only screen and (max-height: 650px) {
  .looks-content {
    width: 100%;
  }
} */
/* @media only screen and (max-height: 750px) {
  .looks-content {
    width: 200px;
  }
} */
@media only screen and (max-height: 820px) {
  .looks-content {
    width: 290px;
  }
}
@media only screen and (max-height: 1200px) and (min-height: 820px) {
  .looks-content {
    width: 400px;
  }
}
@media only screen and (max-height: 1400px) and (min-height: 1200px) {
  .looks-content {
    width: 600px;
  }
}

.shop_page {
  padding: 120px 50px 0px 50px;
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* overflow: scroll; */
  flex-wrap: wrap;
  font-family: "IBM Plex Mono", monospace;
}

.shop_closed {
  font-size: 20px;
}

.product-container {
  min-width: 100px;
  width: 32%;
  margin-bottom: 50px;
}

.product {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product img {
  width: 100%;
  position: relative;
}

.product .title {
  margin: 10px 0 2px 0;
  height: 26px;
  font-size: 20px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
  overflow: hidden;
}

.product .price {
  font-size: 10px;
  height: 10px;
}

.grid_page_odd::after {
  content: "";
  /* flex: auto; */
  width: 32%;
}

@media only screen and (max-width: 675px) {
  .shop_page {
    padding: 75px 12px 0px 12px;
  }
  .product .title {
    margin: 5px 0 0 0;
    font-size: 16px;
    font-family: adobe-garamond-pro, serif;
    transform: scale(0.85, 1);
    transform-origin: bottom left;
    font-style: italic;
  }

  .product .price {
    /* font-size: 8px; */
  }
  .product-container {
    width: 50%;
    margin-bottom: 20px;
  }
  .product-container:nth-of-type(odd) {
    padding-right: 6px;
  }
  .product-container:nth-of-type(even) {
    padding-left: 6px;
  }
}

.about-page {
  padding: 22px;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  /* overflow: scroll; */
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: "IBM Plex Mono", monospace;
}

.about-content {
  max-width: 400px;
  margin: auto;
}

@media only screen and (max-width: 675px) {
  .about-page {
    padding-top: 20px;
    font-size: 10px;
  }
  .about-content {
    max-width: 300px;
    margin: auto;
  }
}

.values-page {
  display: flex;
  flex-direction: row;
  padding: 150px;
  margin: auto 0;
  height: 100vh;
  width: 100%;
  justify-content: center;
}
.values-content {
  /* max-width: 350px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
  margin: auto;
}
.values-content img {
  max-width: 316px;
  max-height: 451px;
  height: auto;
  width: auto;
}

@media only screen and (max-width: 940px) {
  .page .credits-stick {
    position: relative;
  }
  .values-page {
    padding-bottom: 20px;
    height: auto;
  }
}
@media only screen and (max-width: 675px) {
  .values-page {
    padding: 75px 12px 20px 12px;
    height: auto;
  }
}

/* Animation */
.universal-ani-enter {
  opacity: 0;
}
.universal-ani-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}
.universal-ani-exit {
  opacity: 1;
}
.universal-ani-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}

.universal_page {
  padding: 70px 70px 0 70px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* overflow: scroll; */
  /* background-color: ; */
  color: white;
  font-size: 8px;
  font-family: "IBM Plex Mono", monospace;
  margin: auto;
}

.universal_content {
  /* display: flex;
  flex-direction: column;
  width: 100%; */
  /* margin: auto; */
}

.universal_page a {
  cursor: pointer;
}

.universal_page .disabled {
  pointer-events: none;
  cursor: default;
  color: grey !important;
}

.universal_content img {
  width: 375px;
  transform: scale(1, 0.85);
  margin-bottom: -17px;
}
.universal_content p {
  font-size: 18px;
  margin: auto;
  width: 375px;
  font-family: adobe-garamond-pro, serif;
  font-style: normal;
  transform: scale(0.85, 1);
  transform-origin: bottom;
  text-align: left;
  line-height: 22px;
}

.universal-controls {
  display: flex;
  margin: auto;
  position: relative;
  flex-direction: row;
  justify-content: center;
  font-size: 10px;
}

.universal-controls a {
  color: white;
}
.white-writing a {
  color: white;
}

@media only screen and (max-width: 675px) {
  .universal_page {
    padding: 75px 12px 0 12px;
  }
  .universal_content img {
    width: 100%;
  }
  .universal_content p {
    width: 100%;
  }
}

.instagram-page {
  padding: 120px 50px 0px 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: "IBM Plex Mono", monospace;
}

.image {
  position: relative;
  display: inline-block;
  /* width: 29%; */
  flex: 0 0 33%;
  height: auto;
  margin-bottom: 4px;
}
/* .image:nth-child(3n) {
  padding-left: 12px;
}
.image:nth-child(3n-1) {
  padding: 0 12px;
} */

.overlay {
  display: none;
}
.image:hover .overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
}
@media only screen and (max-width: 1100px) {
}
@media only screen and (max-width: 675px) {
  .instagram-page {
    padding: 75px 12px 12px 12px;
  }
  /* .image {
    width: 40%;
    margin: 10px;
  } */
}

.contact-page {
  padding: 12px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* overflow: scroll; */
  font-size: 10px;
  font-family: "IBM Plex Mono", monospace;
}

.contact-form {
  width: 500px;
  max-height: 600px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  margin: auto;
}

.names {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: space-between;
}
.s_name {
  display: flex;
  flex-direction: column;
  width: 242px;
}
.name {
  width: 100%;
}

.inputdiv {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.email_div {
  display: flex;
  flex-direction: column;
  width: 308px;
}

.order_div {
  display: flex;
  flex-direction: column;
  width: 176px;
}

.contact-page label {
  margin: 36px 0 21px 0;
}

.subject-selector {
  height: 30px;
  padding: 5px;
  border-radius: 0;
}
.message {
  height: 100px;
  border-radius: 0;
  resize: none;
  margin: 0 0 18px 0;
}
.submit-button {
  height: 35px;
  width: 200px;
  color: white;
  background-color: black;
  font-family: adobe-garamond-pro, serif;
  font-style: italic;
  font-size: 20px;
  border: 0;
  right: 0;
}

input {
  border: 0;
  border-radius: 0;
  border-bottom: solid 1px gray;
}
input:focus {
  outline: 0;
}

@media only screen and (max-width: 675px) {
  .contact-page {
    padding: 75px 30px;
    height: auto;
  }
  .contact-form {
    width: 100%;
    max-height: 500px;
  }
  .s_name {
    width: 100%;
  }
  .email_div {
    width: 100%;
  }
  .order_div {
    width: 100%;
  }
  .names {
    flex-direction: column;
    height: 100%;
  }
  .submit-button {
    width: 100%;
  }
  .credits-stick {
    position: relative;
  }
}

.react-dropdown-select {
  border: 1px solid black !important;
  border-radius: 0px;
}

.mailing-page {
  padding: 75px;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* overflow: scroll; */
  flex-wrap: wrap;
  font-size: 10px;
  font-family: "IBM Plex Mono", monospace;
}
h1 {
  margin: 0 !important;
}
.mailing-content {
  width: 316px;
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* overflow: scroll; */
  flex-wrap: wrap;
}
.mailing-paragraph-store-closed {
  margin-bottom: 10px;
}
.mailing-page label {
  margin: 30px 0 15px 0;
}
.mailing-page input {
  /* width: 400px; */
  width: 100%;
  border-radius: 0;
  margin: 0px 0 20px 0;
}
.mailing-button {
  height: 35px;
  width: 100%;
  /* width: 400px; */
  color: white;
  background-color: black;
  font-family: adobe-garamond-pro, serif;
  font-style: italic;
  font-size: 20px;
  border: 0;
  right: 0;
}
#mc_embed_signup {
  width: 100%;
}

@media only screen and (max-width: 675px) {
  .mailing-page {
    padding: 20px;
  }
  .mailing-content {
    /* width: 100%; */
  }
}

.article-page {
  padding: 120px 0 0 0;
  /* height: 100vh; */
  width: 100%;
  display: flex;
  /* overflow: scroll; */
  text-align: center;
  font-size: 12px;
  font-family: "IBM Plex Mono", monospace;
}

.article-page p {
  text-align: center !important;
  width: 450px;
  margin: auto;
}

.article-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.article-content button {
  /* width: 650px; */
  margin: auto;
}

.article-content span {
  display: block;
  width: 450px;
  margin: auto;
}

.article-content-img {
  margin: 6px auto;
  width: 650px;
}

.article-title {
  font-size: 26px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom center;
  font-style: italic;
  margin: 0px 0 8px 0;
}

@media only screen and (max-width: 800px) {
  .article-page {
    padding: 75px 20px 0 20px;
  }
  .article-content img {
    /* width: 300px; */
    width: 100%;
    margin: 7px auto;
  }
  .article-content span {
    width: 100%;
  }
  .article-content p {
    width: 100%;
  }
  .article-content .img-span {
    width: 100%;
  }
}

/* INITIALIZERS + DEFAULTS
 * ============================== */
/* @import url("https://use.typekit.net/svf7tgk.css"); */
/* @import url("https://use.typekit.net/iza3vjp.css"); */
* {
  min-height: 0;
  min-width: 0;
}

iframe {
  width: 100%;
  margin: auto;
  /* height: 250px; */
}
/* @media only screen and (max-width: 400px) {
  iframe {
    height: 50%;
  }
} */

.clickable-button:hover {
  cursor: pointer;
  background-color: #3c3937;
}

input,
select {
  font-size: 100%;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.my-node-enter {
  opacity: 0;
  overflow: hidden;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  /* transition: opacity 100ms; */
}

.page {
  display: flex;
  flex-direction: column;
}
.credits {
  margin: 30px 0 20px 0;
  font-family: "IBM Plex Mono", monospace;
  font-size: 8px;
  color: grey;
  text-align: center;
  width: 100%;

  position: relative;
  left: 0;
  bottom: 0;
}

.credits-stick {
  margin-bottom: 20px;
  font-family: "IBM Plex Mono", monospace;
  font-size: 8px;
  color: grey;
  text-align: center;
  width: 100%;

  position: fixed;
  left: 0;
  bottom: 0;
}

p {
  margin: 0;
}
button {
  border: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
  margin: 0px;
}
body {
  font-family: "IBM Plex Mono", monospace;
}

.home-link {
  text-decoration: underline !important;
}
a {
  color: black;
  text-decoration: none !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 65%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

h1 {
  font-weight: 300;
  margin: 0 0 15px;
  font-size: 3rem;
}

h2 {
  font-weight: 300;
  margin: 0;
  font-size: 2rem;
}

/* BASE APP
 * ============================== */
.App__header {
  background-color: #222;
  background-image: url("https://unsplash.it/1000/300?image=823");
  background-size: cover;
  color: white;
  padding: 10px 10px;
}

.App__nav {
  width: 100%;
  list-style: none;
}

.App__customer-actions {
  float: left;
  padding: 10px;
}

.App__title {
  padding: 80px 20px;
  text-align: center;
}

.Product-wrapper {
  max-width: 900px;
  margin: 40px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.App__view-cart-wrapper {
  float: right;
}

.App__view-cart {
  font-size: 15px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
}

/* .button {
  background-color: #2752ff;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 17px;
  cursor: pointer;
}

.button:hover,
.button:focus {
  background-color: #222222;
}

.button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
} */

.login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer;
}

.login:hover {
  color: white;
}

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  transform: translateX(-50%);
}

.Flash__message {
  background: rgba(0, 0, 0, 0.88);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  cursor: default;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
}

.Flash__message--open {
  display: flex;
}

/* PRODUCTS
 * ============================== */
.Product {
  flex: 0 1 31%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
}

.Product__title {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  opacity: 0.7;
}

.Product__price {
  display: block;
  font-size: 1.1rem;
  opacity: 0.5;
  margin-bottom: 0.4rem;
}

.Product__option {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
}

/* CUSTOMER AUTH
 * ============================== */
.CustomerAuth {
  background: #2a2c2e;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  transition: opacity 150ms;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  position: fixed;
}

.CustomerAuth--open {
  display: block;
}

.CustomerAuth__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.CustomerAuth__body {
  padding: 130px 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  position: relative;
}

.CustomerAuth__heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px;
}

.CustomerAuth__credential {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px;
}

.CustomerAuth__input {
  height: 60px;
  padding: 24px 10px 20px;
  border: 0px;
  font-size: 18px;
  background: #fff;
  width: 100%;
}

.CustomerAuth__submit {
  float: right;
}

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: #c23628;
}

