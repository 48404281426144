.shop_page {
  padding: 120px 50px 0px 50px;
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* overflow: scroll; */
  flex-wrap: wrap;
  font-family: "IBM Plex Mono", monospace;
}

.shop_closed {
  font-size: 20px;
}

.product-container {
  min-width: 100px;
  width: 32%;
  margin-bottom: 50px;
}

.product {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product img {
  width: 100%;
  position: relative;
}

.product .title {
  margin: 10px 0 2px 0;
  height: 26px;
  font-size: 20px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
  overflow: hidden;
}

.product .price {
  font-size: 10px;
  height: 10px;
}

.grid_page_odd::after {
  content: "";
  /* flex: auto; */
  width: 32%;
}

@media only screen and (max-width: 675px) {
  .shop_page {
    padding: 75px 12px 0px 12px;
  }
  .product .title {
    margin: 5px 0 0 0;
    font-size: 16px;
    font-family: adobe-garamond-pro, serif;
    transform: scale(0.85, 1);
    transform-origin: bottom left;
    font-style: italic;
  }

  .product .price {
    /* font-size: 8px; */
  }
  .product-container {
    width: 50%;
    margin-bottom: 20px;
  }
  .product-container:nth-of-type(odd) {
    padding-right: 6px;
  }
  .product-container:nth-of-type(even) {
    padding-left: 6px;
  }
}
