.news_page {
  padding: 120px 50px 0px 50px;
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.news-article {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-article img {
  width: 100%;
  position: relative;
}

.news-article .news-article-title {
  margin: 10px 0 2px 0;
  height: 26px;
  font-size: 20px;
  font-family: adobe-garamond-pro, serif;
  transform: scale(0.85, 1);
  transform-origin: bottom left;
  font-style: italic;
  overflow: hidden;
}

.news-article .news-article-date {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  /* line-height: 18px; */
  font-size: 10px;
  height: 10px;
}
.grid_page_odd::after {
  content: "";
  /* flex: auto; */
  width: 32%;
}

@media only screen and (max-width: 675px) {
  .news_page {
    padding: 75px 12px 0px 12px;
  }
  .news-article .news-article-title {
    margin: 5px 0 0 0;
    font-size: 16px;
  }

  .news-article .news-article-date {
    /* font-size: 8px; */
  }
  .news_page .product-container:nth-of-type(odd) {
    padding-right: 6px;
  }
  .news_page .product-container:nth-of-type(even) {
    padding-left: 6px;
  }
}
