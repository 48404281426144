.mailing-page {
  padding: 75px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* overflow: scroll; */
  flex-wrap: wrap;
  font-size: 10px;
  font-family: "IBM Plex Mono", monospace;
}
h1 {
  margin: 0 !important;
}
.mailing-content {
  width: 316px;
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* overflow: scroll; */
  flex-wrap: wrap;
}
.mailing-paragraph-store-closed {
  margin-bottom: 10px;
}
.mailing-page label {
  margin: 30px 0 15px 0;
}
.mailing-page input {
  /* width: 400px; */
  width: 100%;
  border-radius: 0;
  margin: 0px 0 20px 0;
}
.mailing-button {
  height: 35px;
  width: 100%;
  /* width: 400px; */
  color: white;
  background-color: black;
  font-family: adobe-garamond-pro, serif;
  font-style: italic;
  font-size: 20px;
  border: 0;
  right: 0;
}
#mc_embed_signup {
  width: 100%;
}

@media only screen and (max-width: 675px) {
  .mailing-page {
    padding: 20px;
  }
  .mailing-content {
    /* width: 100%; */
  }
}
